"use strict";
/**
 * Main LOW object -- Name Space
 */
let LOW = new Object();

/**
 * Home Tabs
 *
 * Loops through highlighted items, stops the loop on tab click
 */
(function(){
	let tabs   = document.querySelectorAll('.home .local a');
	let addons = document.querySelectorAll('#primary .addon');

	if (!tabs.length) return;

	function activate(i) {
		let tab = tabs[i] || false;
		let addon = addons[i] || false;
		if (!tab || !addon) return;
		tabs.forEach(function(el){ el.parentNode.classList.remove('active') });
		addons.forEach(function(el){ el.classList.remove('active') });
		tab.parentNode.classList.add('active');
		addon.classList.add('active');
	};

	tabs.forEach(function(el, i){
		el.addEventListener('click', function(event){
			event.preventDefault();
			activate(i);
			if (timer) clearTimeout(timer);
		})
	});

	let active = 0;
	let timer = setInterval(function(){
		active++;
		if (active >= tabs.length) active = 0;
		activate(active);
	}, 7500);
})();


/**
 * Low Box
 *
 * Opens a given image url in a lightbox, lean and mean
 */
LOW.Box = new (function(){

	let that = this;

	this.image = function(url) {
		// Empty overlay
		that.overlay.innerHTML = '';

		// Add overlay to body
		document.body.appendChild(that.overlay);

		// Get image dimensions from url
		let i = url.match(/#(\d+)x(\d+)$/);

		// Determine margin top...
		let mt = ((window.innerHeight - i[2]) / 2);
		//var mt = 0;

		// ...which cannot be negative
		if (mt < 0) mt = 0;

		let img = document.createElement('img');
		img.src = url;
		if (mt) img.style.marginTop = mt + 'px';

		that.overlay.appendChild(img);

		that.is_open = true;
	};

	this.page = function(url) {
		// Add overlay to body
		document.body.appendChild(that.overlay);

		// Add the content div to it
		that.overlay.appendChild(that.content);

		// Add loading class
		that.content.classList.add('loading');

		// Calculate units
		let unit = window.innerHeight / 8;

		// Nice placement
		that.content.style.height = (unit * 6 - that.gutter * 2) + 'px';
		that.content.style.marginTop = unit + 'px';

		var request = new Request(url, {
			headers: new Headers({
				'Content-Type': 'text/plain',
				'X-Requested-With': 'XMLHttpRequest'
			})
		});

		fetch(request).then((data) => {
			return data.text();
		}).then((text) => {
			that.content.classList.remove('loading');
			that.content.innerHTML = text;
		});

		that.is_open = true;
	}

	this.close = function() {
		that.overlay.innerHTML = '';
		that.overlay.remove();
		that.is_open = false;
	};

	this.isOpen = function() {
		return that.is_open;
	};

	this.overlay = document.createElement('div');
	this.overlay.id = 'box-overlay';
	this.overlay.addEventListener('click', this.close);

	this.content = document.createElement('div');
	this.content.id = 'modal';
	this.content.className = 'overlay';

	this.gutter  = 20;
	this.is_open = false;

	return this;
});

//LOW.Box = new LOW.Box;

/**
 * Addon Thumbnails
 *
 * Creates slider from thumbnails, activates a given thumbnail on click,
 * and opens activated in LOW.Box when clicked
 */
 (function(){
	 let figure = document.querySelector('figure.active');
	 if (!figure) return;

	 figure.addEventListener('click', function(event){
		event.preventDefault();
		LOW.Box.image(this.querySelector('a').href);
	 });

	 let ul = document.querySelector('ul.thumbs');
	 if (!ul) return;

	 let icon = document.createElement('i');
	 icon.className = 'fa fa-search-plus';
	 let thumbs = ul.querySelectorAll('a');
	 let active = 0;

	 let activate = function(i) {
		 let thumb = thumbs[i] || false;
		 if (!thumb) return;
		 let dupe = thumb.cloneNode(true);
		 dupe.appendChild(icon.cloneNode());

		 figure.innerHTML = '';
		 figure.appendChild(dupe);
		 thumbs.forEach(function(a){ a.parentNode.classList.remove('active') });
		 thumb.parentNode.classList.add('active');
		 active = i;
	 };

	thumbs.forEach(function(a, i){
		a.addEventListener('click', function(event){
			event.preventDefault();
			activate(i);
		})
	 });

	 // Prev/next
	 document.addEventListener('keydown', function(event){
		 if ( ! LOW.Box.isOpen()) return;
		 if ( ! (event.keyCode == 37 || event.keyCode == 39)) return;

		 // Prev
		 if (event.keyCode == 37) {
			 active--;
			 if (active < 0) active = thumbs.length - 1;
		 }
		 // Next
		 else {
			 active++;
			 if (active >= thumbs.length) active = 0;
		 }

		 activate(active);
		 figure.click();
	 });

	 activate(active);
 })();


/**
 * Open the license agreement link in LOW.Box
 */
(function(){
	let link = document.querySelector('a[href$="license-agreement"]');
	if (!link) return;
	link.addEventListener('click', function(event){
		event.preventDefault();
		LOW.Box.page(this.href);
	});
})();


/**
 * Modal
 *
 * Position the modal/message window just right
 */
// LOW.Modal = function() {
// 	var element = $('#modal');
// 	if ( ! element) return;
//
// 	// Minimum margin-top
// 	var min = 10;
//
// 	// Set new margin top
// 	var set = function() {
// 		var mt = ($(window).height() - element.height()) / 3;
// 		if (mt < min) mt = min;
// 		element.css('margin-top', mt);
// 	}
//
// 	$(window).resize(set);
// 	set();
// };

/**
 * Docs navigation: fixed/responsive
 *
 */
(function(){
	let nav = document.querySelector('nav.docs');
	if (!nav) return;

	let threshold = Math.round(nav.getBoundingClientRect().top + window.pageYOffset);

	let monitor = function(){
		let method = (threshold < window.scrollY) ? 'add' : 'remove';
		nav.classList[method]('fixed');
	};

	window.addEventListener('scroll', monitor);
	monitor();

	let h2 = nav.querySelector('h2');
	h2.addEventListener('click', function(event){
		event.preventDefault();
		nav.classList.toggle('open');
	});
})();

/**
 * Anchors in documentation
 */
(function(){
	let docs = document.querySelector('div.docs');
	if (!docs) return;
	docs.querySelectorAll('h2, h3, h4').forEach(function(el){
		if (!el.id) return;
		let a = document.createElement('a');
		a.className = 'anchor';
		a.href = '#' + el.id;
		a.innerHTML = '#';
		el.appendChild(a);
	});
})();

/**
 * Filter dropdown
 *
 * Replaces an UL with links to a SELECT with OPTIONs and an onchange event handler
 */
(function(){
	document.querySelectorAll('#filter ul').forEach(function(el){
		let links  = el.querySelectorAll('a');
		let select = document.createElement('select');
		select.addEventListener('change', function(){ location.href = this.value });
		el.parentNode.replaceChild(select, el);
		links.forEach(function(a){
			let option = document.createElement('option');
			option.value = a.href;
			option.innerHTML = a.innerHTML;
			option.selected = a.classList.contains('active');
			select.appendChild(option);
		});
	});
})();
